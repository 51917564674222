import React, { useState }  from "react";
import useApiCajaAhorro from "../../api/administracion/useApiCajaAhorro";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import agGridHelper from "../../shared/ag-grid-helper";
import ButtonIcon from "../../shared/controls/ButtonIcon";
import Filtro from "../../shared/componentes/filtro";
import FieldCalendarRange from "../../shared/controls/fieldCalendarRange";
import FieldMultiSelect from "../../shared/controls/fieldMultiSelect";
import { getSessionState } from "../../hooks/useSessionState";
import { Sidebar } from "primereact/sidebar";
import alerts from "../../shared/alerts";

const PrestamoCajaAhorro = () => {
    const [datos, setDatos] = useState([]);  
    const filtroKey = 'FiltroAhorro';
    const api = useApiCajaAhorro();
    const [gridApi, setGridApi] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    async function buscar(filtro, paramsApi) {

        agGridHelper.showLoad(gridApi, paramsApi, true);
        setDatos(await api.obtenerPrestamo(obtenerFiltro(filtro)));
        agGridHelper.hideLoad(gridApi, paramsApi, false);     
      
    }
    const RowButtons = () => (
        <div className="ag-grid row-icons">
           
        </div>
    );
    const onGridReady = async (params) => {
        setGridApi(params.api);
    };
    const obtenerFiltro = (filtro) => {
        filtro = filtro ?? getSessionState(filtroKey);

        return { ...filtro};
    };
   async  function limpiar(){
        const confirmacion = await alerts.preguntarSiNoAdvertencia("¿Está seguro de limpiar Prestámo de Caja de Ahorro a TODOS los empleados?");
        if (confirmacion) {
            api.quitarPrestamoAhorro()
        }
    }
    const ButtonsHeader = () => {
        return (
            <div className="ag-grid header-icons">
                <ButtonIcon title="Descargar" onClick={exportar} iconName="BiDownload" />
                <ButtonIcon title="Limpiar Prestámo Caja ahorro a TODOS los empleados" onClick={limpiar} iconName="RiDeleteBinLine" />
            </div>
        );
    };
    function exportar() {
        api.excelPrestamo(getSessionState(filtroKey));
    }

    return (
        <>
      
            <Sidebar visible={sidebarVisible} position="right" dismissable={false}
                className="p-sidebar-xl" onHide={() => setSidebarVisible(false)}>
            </Sidebar>

            <Filtro filtroKey={filtroKey} handleSubmit={buscar} filtrarAlInicio={false}
                classButtons="field-iconos btn-group">
                <Filtros />
            </Filtro>

            <div className="ag-theme-balham grid-principal">
                <AgGridReact
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
                      frameworkComponents={
                          {
                              buttonsHeader: ButtonsHeader,
                              rowButtons: RowButtons,
                              goToDetail: agGridHelper.GoToDetailFormatter,
                              dateFormatter: agGridHelper.DateTimeFormatter,
                          }
                      }
                    onGridReady={onGridReady}
                    rowData={datos}
                    overlayLoadingTemplate={agGridHelper.loadingOverlay}
                    defaultColDef={agGridHelper.defaultColumns}
                    rowHeight={35}
                >
                    <AgGridColumn maxWidth={200} field="fecha" headerName="FECHA" cellRenderer="dateFormatter" />
                    <AgGridColumn field="empleado" headerName="EMPLEADO" flex={5} minWidth={280} cellClass={'left'} headerClass={'left'} />
                    <AgGridColumn maxWidth={200} field="descuentoPrestamo" headerName="DESCUENTO PRÉSTAMO CAJA AHORRO" cellStyle={{ textAlign: "right" }}
                        valueFormatter={agGridHelper.currencyFormatter} />
                   
                    <AgGridColumn maxWidth={80}
                        headerName="VER"
                        cellRenderer="rowButtons"
                        pinned={'right'}
                        cellClass={'left'}
                        headerComponent="buttonsHeader" />
                </AgGridReact>
            </div>
        </>
    )
}
const Filtros = ({ filtro, onChange }) => {
   
    return (
        <>
  
         <FieldMultiSelect name="empleadosId" source="empleados"   label="Empleado" colLg={2} colMd={3}
                value={filtro.empleadosId}  onChange={onChange} showFilter valueAsId={true} />

            <FieldCalendarRange name="fechas" label="Fechas" colLg={2} colMd={3} value={filtro.fechas}
                onChange={onChange} />
        </>
    )
};
export default PrestamoCajaAhorro;
