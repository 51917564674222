import useServiceFactory from "../serviceFactory";

const apiUrl =  `/administracion/liquidacionReporte`;
const apiUrlAhorro = `/administracion/cajaAhorro`;
const useApiCajaAhorro = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_API_URL });
    return {
        obtenerAhorro: async (params) => service.get(`${apiUrl}/obtenerAhorro`, { params, showLoading: true }),
        obtenerPrestamo: async (params) => service.get(`${apiUrl}/obtenerPrestamo`, { params, showLoading: true }),
        quitarAhorro : async (params) => service.post(`${apiUrlAhorro}/quitarAhorro`, { params, showLoading: true }),
        quitarPrestamoAhorro : async (params) => service.post(`${apiUrlAhorro}/quitarPrestamoAhorro`, { params, showLoading: true }),        
        excelCajaAhorro: async (params) => service.get(`${apiUrl}/ahorroexcel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `CajaAhorro.xlsx`
            }),
        excelPrestamo: async (params) => service.get(`${apiUrl}/prestamoAhorroexcel`,
        {
            params,
            showLoading: true,
            download: true,
            fileName: `PrestamoCajaAhorro.xlsx`
        }),
        }
};

export default useApiCajaAhorro;